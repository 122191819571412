export default function Footer() {
  return (
    <footer class="mx-auto mt-32 w-full max-w-container px-4 sm:px-6 lg:px-8">
      <div class="border-t border-slate-900/5 py-10">
        <p class="mt-5 text-center text-sm leading-6 text-slate-500">
          © 2023 Achedoso. Todos los derechos reservados.
        </p>
        <div class="mt-16 flex items-center justify-center space-x-4 text-sm font-semibold leading-6 text-slate-700">
          <a href="#">Política de privacidad</a>
          <div class="h-4 w-px bg-slate-500/20"></div>
          <a href="#">Changelog</a>
        </div>
      </div>
    </footer>
  );
}

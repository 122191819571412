import {
  TruckIcon,
  UserGroupIcon,
  ShoppingBagIcon,
  CubeIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Maneja la base de datos de clientes",
    description:
      "Esta funcionalidad permite gestionar y almacenar la información de los clientes, como sus nombres, direcciones, números de teléfono, entre otros datos relevantes, lo que permite un mejor seguimiento y atención personalizada de los mismos.",
    icon: UserGroupIcon,
  },
  {
    name: "Ordena la recepción de pedidos",
    description:
      "Esta función permite recibir los pedidos de los clientes de manera rápida y sencilla, lo que facilita la gestión de los mismos y evita errores en la toma de órdenes.",
    icon: ShoppingBagIcon,
  },
  {
    name: "Gestiona stock y precios de tus productos",
    description:
      "Esta funcionalidad permite mantener actualizado el inventario de los productos disponibles, así como sus precios, lo que ayuda a una gestión eficiente de los recursos y una mejor toma de decisiones en cuanto a la oferta de productos.",
    icon: CubeIcon,
  },
  {
    name: "Planifica rutas de entrega",
    description:
      "Esta funcionalidad permite planificar de manera efectiva las rutas de entrega de los productos a los clientes, lo que ayuda a optimizar los tiempos y costos de transporte, y a mejorar la calidad del servicio.",
    icon: TruckIcon,
  },
];
export default function Features() {
  return (
    <div className="bg-white py-24 sm:py-32" id="product">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-600">
            Mejora la gestión de venta de agua purificada con nuestro software
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Todo lo que necesitas en una app
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Dedícate a hacer crecer tu negocio, nosotros nos encargamos de
            apoyarte en el resto
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
